/* eslint-disable */
import { base, baseUpload, baseXlsx } from '@/utils/api'

const ENDPOINT = '/items'

export default {
    getCsvTemplate(type) {
        const config = {
            params: { type }
        };
        return baseXlsx.get(`${ENDPOINT}/xlsx/template`, config);
    },
    importItemsXlsx(clinicId, data) {
        return baseUpload.post(`${ENDPOINT}/xlsx/import?clinicId=${clinicId}`, data);
    },
    getItem(id, clinicHealthPlanId = null, planId = null) {
        const config = {
            params: { clinicHealthPlanId, planId }
        };
        return base.get(`${ENDPOINT}/${id}`, config);
    },
    searchItems(clinicId, query, types, clinicHealthPlanId = null, planId = null) {
        const config = {
            params: { clinicId, query, types, clinicHealthPlanId, planId }
        };
        return base.get(`${ENDPOINT}/search/items`, config);
    },
    searchItemsByType(clinicId, type) {
        const config = {
            params: { clinicId, type }
        };
        return base.get(`${ENDPOINT}/search-by-type/items`, config);
    },
    getItems(page, clinicId, query) {
        const config = {
            params: { page, clinicId, query }
        };
        return base.get(`${ENDPOINT}`, config);
    },
    getItemsByTableId(page, tableId, query, types, status) {
        const config = {
            params: { page, query, types, status }
        };
        return base.get(`${ENDPOINT}/${tableId}/table`, config);
    },
    getItemIds(clinicId) {
        const config = {
            params: { clinicId }
        };
        return base.get(`${ENDPOINT}/batch/item-ids`, config);
    },
    createItem(props) {
        return base.post(`${ENDPOINT}`, props);
    },
    updateItem(id, props) {
        return base.put(`${ENDPOINT}/${id}`, props);
    },
    deleteItem(id) {
        return base.delete(`${ENDPOINT}/${id}`);
    },
    deleteItems(ids) {
        return base.put(`${ENDPOINT}/batch/delete`, { ids });
    },
    searchItemsByTypeAndClinicId(types, clinicId, query = '', paginated = false) {
        const config = {
            params: { types, query, paginated }
        };
        return base.get(`${ENDPOINT}/${clinicId}/search-by-type`, config);
    }
}