<template>
    <div>
        <multiselect
            id="item-select"
            ref="itemMultiselect"
            label="name"
            track-by="id"
            v-model="query"
            :options="items"
            :showLabels="false"
            :internal-search="false"
            :searchable="true"
            :allowEmpty="allowEmpty"
            :placeholder="placeholder"
            @search-change="searchDebounce"
            @select="onSelect"
            class="with-border"
            :disabled="disabled"
        >
            <template slot="caret">

                <div  class="icon-loading">
                    <b-spinner v-if="loading" small type="grow" variant="primary"></b-spinner>
                    <CloseSmall v-if="!loading && query" @click="clearSelection" />
                </div>
                <div class="create-item" v-if="hasCreateItem" @click="openModal">Criar item</div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
                <span class="item-label">{{ ITEM.parseTypeToLabel(option.type)}} | </span>
                <span v-if="option.code" class="code">{{ option.code }} - </span>
                <span 
                    v-if="option.name.length < 40"
                    class="item-label" 
                >
                    {{ option.name }}
                </span>
                <span 
                    v-else
                    class="item-label"
                    v-b-tooltip.hover :title="option.name"
                >
                    {{ option.name.substring(0,40)}} ...
                </span>
            </template>
            <template slot="option" slot-scope="{ option }">
                <span class="item-label">{{ ITEM.parseTypeToLabel(option.type) }}  | </span>
                <span v-if="option.code" class="code">{{ option.code }} - </span>
                <span 
                    v-if="option.name.length < 40"
                    class="item-label" 
                >
                    {{ option.name }}
                </span>
                <span 
                    v-else
                    class="item-label"
                    v-b-tooltip.hover :title="option.name"
                >
                    {{ option.name.substring(0,40)}} ...
                </span>
            </template>
            <template slot="noOptions">Realize uma busca para obter resultados</template>
            <template slot="noResult">Nenhum resultado encontrado</template>
        </multiselect>

        <ItemModal 
            :types="types"
            @saved="createdItem"
            v-if="hasCreateItem"
        />
    </div>
</template>
<script>
import api from '@items/api'
import * as ITEM from '@items/utils/constants'
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        CloseSmall: () => import('@/assets/icons/close-small.svg'),
        ItemModal: () => import('@items/modals/ItemModal')
    },
    props: {
        value: Object,
        types: Array,
        validated: Boolean,
        placeholder: String,
        allowEmpty: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        hasCreateItem: { type: Boolean, default: true }
    },
    mounted() {
        this.searchDebounce = debounce(this.searchItems, 300);
        this.populateValue();
    },
    data() {
        return {
            ITEM,
            loading: false,
            clinic: getCurrentClinic(),
            query: null,
            items: [],
            searchDebounce: Function
        }
    },
    methods: {
        searchItems(query) {
            this.loading = true;
            api.searchItems(this.clinic.id, query, this.types)
                .then(({ data }) => (this.items = data))
                .catch(error => this.$toast.error(error.message))
                .finally(() => (this.loading = false));
        },
        onSelect(item) {
            this.$emit('select', item);
            this.$emit('input', item);
        },
        populateValue() {
            if (this.value) {
                this.items = [this.value];
                this.query = this.value;
            } else {
                this.query = this.value
            }
        },
        clearSelection() {
            this.query = null;
            this.value = null;
            this.$emit('input', null);
            this.$emit('select', null);
        },
        openModal($event) {
            $event.stopPropagation();
            this.$bvModal.show('items-modal');
        },
        createdItem(item) {
            this.items = [item];
            this.query = item;
            this.onSelect(item);
            setTimeout(() => {
                this.$refs.itemMultiselect.$refs.search.blur()
            }, 300);
        }
    },
    watch: {
        value: {
            handler(value) {
                this.populateValue();
            },
            immediate: true
        }
    }
    
}
</script>
<style lang="scss" scoped>

.icon-loading {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
    z-index: 10;
    svg {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
    }
}

.create-item {
    position: absolute;
    top: -24px;
    right: 0;
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--blue-500);
    cursor: pointer;
}

.code {
    font-size: 0.8rem;
    color: var(--blue-500);
}
</style>